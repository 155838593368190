.tables-container {
  display: flex;
  gap: 24px;
  margin-top: 40px;
}

.table-section {
  flex: 1;
  max-height: 350px;
  box-shadow: 0px 0px 16px 0px #00000040;
  background-color: #fff;
  border-radius: 10px;
  padding: 8px 0px;
  /* overflow-y: auto; */
}

.table-section h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}

.status-green {
  background-color: #39dc00;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-yellow {
  background-color: #ffe70f;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.table-checkbox {
  width: 20px;
  height: 20px;
  accent-color: #bf1d2d;
}

.live_table {
  height: 90%;
  overflow-y: auto !important;
  padding: 0px 10px;
}

.live_table .rdt_TableHead {
  display: none !important;
}

.live_table .rdt_TableRow {
  border: none !important;
}

.note_input {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 16px 18px;
}

.modal_textarea {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 9px 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.red-text {
  color: #bf1d2d;
}

.search-bar {
  border: 1px solid rgb(94, 94, 94);
  border-radius: 10px;
  padding: 4px 10px;
  width: 50%;
}

.search-bar input {
  width: 88%;
  border: 0;
  outline: none;
}


.space-cutter-class {
  white-space: break-spaces !important;
}