.pages-content{
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.07) !important;
    border-radius: 30px !important;
    padding: 4px 12px !important;
    margin-left: 40px !important;
    font-family: 'Karla' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #f5f5f5 !important;
}
.pages-content:active,
.pages-content:focus,
.pages-content:hover{
    border: 1px solid transparent !important;
    background-color: rgba(255, 255, 255, 0.07) !important;
    color: #FF4B4B!important;
}
.header-menu{
    display: flex;
    align-items: center;
    justify-content:end !important;
    margin-left: auto;
}

@media only screen and (max-width:600px){
    .app-bar{
        display: none !important;
    }
}