@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.google{
    color: #fff;
    display: flex;
    align-items:center;
    justify-content: space-between;
    border: 0.5px solid #cdcdcd;
    border-radius: 60px;
    padding: 15px 10px;
    width: 210px;
    height: 46px;
    margin-top: 0px !important;
}
.googlee{
    display: none;
}
.sign-in{
    font-size: 16px;
    font-weight: 500;
    font-family: 'Raleway';
}

@media only screen and (max-width: 600px) {
    .sign-inn{
        font-size: 12px;
        font-family: 'Raleway';
    }
    .google{
        display: none;
    }
    .googlee{
        display: block;
        padding: 10px 12px !important;
        color: #fff;
        display: flex;
        align-items:center;
        justify-content: space-between;
        border: 0.5px solid #cdcdcd;
        border-radius: 60px;
        width: 100%;
        height: 35px;
        margin-top: -10px !important;
    }
}