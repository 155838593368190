.RaLayout-appFrame{
    margin-top: 0 !important;
}
.RaMenuItemLink-icon{
    color: #fff !important;
}

.RaSidebar-fixed{
    height: 100vh !important;
    background-color: #212121;
}
@media screen and (min-width: 600px) {
    .d-md-none{
        display: none !important;
    }
}
.RaLayout-content{
    background-color: #F5F5F5 !important;
    padding: 0 !important;
}

.date-dicker div:first-child{
    background-color: #fff !important;
    border: solid 1px #D0D5DD !important;
    border-radius: 6px;
}
.date-dicker div:first-child fieldset{
    border-color: transparent !important;
}
.date-dicker div::before{
    border: none !important;
    right: unset;
    left: 0;
}

[title="Sort"]{
    display: none!important ;
}
.MuiDrawer-root.MuiDrawer-docked.RaSidebar-docked.RaSidebar-appBarCollapsed{
    margin-top: 0px !important;
}
.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor{
    background-color: #F9FAFB !important;
}
[aria-label="Show filters"] , [aria-label="Export"] {
    color : #48535B !important ;
}
.MuiDataGrid-root.MuiDataGrid-root--densityStandard {
    border: none !important;
}
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
.mysticky{
    position: sticky;
    position: -webkit-sticky;
    top:0;
    z-index: 100;
    background-color: #FFF;
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
}


.accordion-work  .css-8je8zh-MuiTouchRipple-root{
    display: none !important;
}

.mobile-nav{
    display: none ;
}

@media only screen and (max-width: 600px) {
    .mobile-nav{
        display: block;
    }
    .mennu .MuiPaper-root{
        height: auto !important;
        background-color: transparent !important;
    }
}
