*{
    font-family: 'Karla';
    font-style: normal;
}
.data-base-container{
    margin: 45px;
}

.datagrid-work .MuiDataGrid-columnHeaders {
    display: none !important;
}

.copy-database-container-mobile{
    display: none !important;
}
.lockDB{
    margin-left: 12px;
}

@media screen and (max-width: 600px) {
    .data-base-container{
        margin: 20px !important;
    }
    .copy-database-container{
        display: none !important;
    }
    .copy-database-container-mobile{
        display: block !important;
    }
    .side-menu .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters{
        color: #000000 !important;
    }
    .lockDB{
        margin-left: 15px;
        margin-bottom: 16px;
    }
}
