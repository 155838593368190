.calendar-header-item {
    flex: 1 0 calc(100% / 7);
    max-width: calc(100% / 7);
    box-sizing: border-box;
    padding: 10px;
}

.shift-count {
    padding: 3px 4px;
    border-radius: 4px;
    background: linear-gradient(
            0deg,
            rgba(255, 59, 59, 0.1) 0%,
            rgba(255, 59, 59, 0.1) 100%
        ),
        #fff;
    color: #e92c2c;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #e92c2c;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.MuiBottomNavigation-root {
    display: flex !important;
    flex-wrap: nowrap !important;
    max-width: 95vw !important;
    padding: 0 5;
    padding-bottom: 5px !important;
    overflow-x: scroll;
}

.small-navigator * {
    max-width: 80vw !important;
}

@media (max-width: 700px) {
  .small-navigator * {
    zoom: 90%
  }
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
