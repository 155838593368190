body {
  margin: 0;
  background: #0e1623;
}

.main-login {
  background: url('/public/images/main-login-img.jpg');
  background-size: cover;
  height: 100vh;
}

.login-content {
  background: url('/public/images/login-blur-big-box.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: grid;
  place-items: center;
}

.login-box-1, .login-box-2, .login-box-3, .login-box-4{
  background: url('/public/images/login-blur-box.svg');
  padding: 50px;
  border: 1px solid #555555;
}


.mainIconBox{
  position: absolute;
  top: 5%;
  left: 5%;
  transform: translate(-5%, -5%);
}

.textField{
  margin-bottom: 20px;
}

.textField .MuiInput-underline:before {
  border-bottom: 1px solid #cdcdcd; 
}

.textField .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid white; 
}

.textField .MuiInput-underline:after {
  border-bottom: 1px solid white; 
}

.textField .MuiInput-underline:before{
  margin-top: 10px;
}

.button-error{
  width: 320px !important;
  height: 48px !important;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px !important;
}

.outlined-input {
  width: 50px;
  height: 50px;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  padding: 0;
}

.outlined-input .MuiOutlinedInput-notchedOutline {
  border-color: #cdcdcd;
}

.outlined-input:hover .MuiOutlinedInput-notchedOutline {
  border-color: #cdcdcd !important;
}

.outlined-input.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill,
textarea:-moz-autofill,
select:-moz-autofill {
  box-shadow: 0 0 0px 1000px transparent inset;
  -moz-text-fill-color: #fff;
}

::placeholder {
  color: white;
}


/* For Firefox */
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: white;
}

input::selection {
  background: none;
}


.forgot-pass{
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family:'Raleway';
  text-align: center;
}

.forgott{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:28px;
}

.forgot h2{
  color: #fff;
  text-align: center;
  font-size: 28px !important;
  font-weight: 700;
  font-family: 'Roboto';
}
.boxes{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Email p{
  color: #c5c5c5;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Roboto' !important;
}
.Email p>a{
  color: #fff;
}
.Email-1 p{
  color: #c5c5c5;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Raleway' !important;
}
.Email-1 p>a{
  color: #fff;
}
.img-2{
  display: none;
}
.mainIconBox-1{
  display: none;
}


@media only screen and (max-width: 600px) {
  .login-box-4 {
    width: 284px;
    height: 347px !important;
    padding: 30px 40px  !important;
  }
  .login-box-3{
    padding: 30px 40px  !important;
    width: 284px !important;
    height: 290px !important;
  }
  .login-box-2{
    padding: 30px 40px  !important;
    width: 284px !important;
    height: 360px !important;
  }
  .login-box-1{
    padding: 30px 40px  !important;
    width: 284px !important;
    height: 400px !important;
  }
  .main-login{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .login-content{
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: grid;
    place-items: center;
  }
  .forgot h2{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  .forgott{
    gap:10px;
    margin-top: -5px !important;
  }
  .outlined-input {
    width: 40px;
    height: 40px;
  }
  .forgot-pass{
    font-size: 12px;
  }
  .button-error{
    width: 173px !important;
    height: 36px !important;
    font-size: 14px !important;
    margin-top: -10px;
  }
  .img-1{
    display: none;
  }
  .img-2{
    display: block;
  }
  .mainIconBox{
    display: none;
  }
  .mainIconBox-1{
    display: block;
  }
  .mainIconBox-1{
    position: absolute;
    top: 5%;
    left: 15%;
    transform: translate(-5%, -5%);
  }
  .Email p{
    font-size: 16px;
    margin-top: -5px !important;
  }
  .Email-1 p{
    font-size: 12px;
  }

}
